/* eslint-disable no-plusplus */
import XLSX from 'sheetjs-style';
import * as FileSave from 'file-saver';
import { CustomObjectProperty } from 'views/backoffice/CustomObjects/types';

export const generateImportListObjectValuesHeaders = (objectProperties: CustomObjectProperty[], sortHeader: boolean = true) => {
    const dataTypes: string[] = [];
    const names: string[] = [];
    if (sortHeader) {
        objectProperties?.sort((a, b) => a.name.localeCompare(b.name));
    }

    objectProperties.forEach((property) => {
        dataTypes.push(property.dataType);
        names.push(`${property.name} ${property.isRequired ? '*' : ''}`);
    });
    return [dataTypes, names];
};

const ec = (r: number, c: number) => XLSX.utils.encode_cell({ r, c });

const deleteExcelRow = (ws: XLSX.WorkSheet, row_index: number) => {
    const variable = XLSX.utils.decode_range(String(ws['!ref']));
    for (let R = row_index; R < variable.e.r; ++R) {
        for (let C = variable.s.c; C <= variable.e.c; ++C) {
            ws[ec(R, C)] = ws[ec(R + 1, C)];
        }
    }
    variable.e.r--;
    ws['!ref'] = XLSX.utils.encode_range(variable.s, variable.e);
};

export const downloadImportListValuesTemplate = (objectProperties: CustomObjectProperty[], sortHeader: boolean = true) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const template = objectProperties.length > 0 ? generateImportListObjectValuesHeaders(objectProperties, sortHeader) : [{ Value: '' }];
    const ws = XLSX.utils.json_to_sheet(template);
    const wb = XLSX.utils.book_new();
    // Delete first row since it was generate from array
    if (objectProperties.length > 0) deleteExcelRow(ws, 0);
    XLSX.utils.book_append_sheet(wb, ws, 'Template');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSave.saveAs(data, `Template${fileExtension}`);
};
