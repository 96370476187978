import { format } from 'date-fns';
import { ChevronRight, Edit, Link } from '@mui/icons-material';
import { Box, Chip, IconButton } from '@mui/material';
import { GridActionsCellItem, GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { IssuerEnum, LIST_DATA_TYPE_TRANSLATION, ListObject, ListValue, listDataTypeEnum } from './types';
import dayjs from 'dayjs';
import { ITenantType } from '../tenantTypes/types';
import { CustomObjectProperty } from '../CustomObjects/types';
import { GridToggleChipAdapter } from 'ui-component/forms/ToggleChip/adapters';

export const customListColumns = (
    onEdit: (row: ListObject) => () => void,
    onEnter: (row: ListObject) => () => void
): GridColDef<ListObject>[] => [
    { field: 'listName', headerName: 'List', width: 200 },
    { field: 'description', headerName: 'Description', width: 300 },
    {
        field: 'origin',
        headerName: 'Backoffice Managed',
        align: 'center',
        width: 150,
        renderCell: ({ value }: GridRenderCellParams<IssuerEnum>) =>
            value === 'BackOffice' ? (
                <Chip
                    label="Yes"
                    size="small"
                    sx={(theme) => ({
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
                        color: theme.palette.success.dark
                    })}
                />
            ) : (
                <Chip
                    label="No"
                    size="small"
                    sx={(theme) => ({
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light + 80,
                        color: theme.palette.orange.dark
                    })}
                />
            )
    },
    {
        field: 'dataType',
        headerName: 'List Type',
        width: 125,
        valueFormatter: ({ value }: GridValueFormatterParams<listDataTypeEnum>) => LIST_DATA_TYPE_TRANSLATION[value]
    },
    {
        field: 'createdAt',
        type: 'date',
        headerName: 'Date Created',
        width: 225,
        valueFormatter: ({ value }) => (value ? format(new Date(value), 'yyyy/MM/dd p') : ''),
        flex: 1
    },
    // {
    //     field: 'recordTypes',
    //     headerName: 'Record Types related',
    //     width: 170,
    //     valueFormatter: ({ value }) => value?.join(', ') || ''
    // },
    {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        align: 'left',
        hideable: false,
        sortable: false,
        filterable: false,
        cellClassName: 'actions',
        renderCell: ({ row }: GridRenderCellParams<any, ListObject>) => {
            const actions = [
                <GridActionsCellItem
                    key="edit"
                    icon={<Edit />}
                    label="Edit"
                    className="textPrimary"
                    color="inherit"
                    onClick={onEdit(row)}
                />
            ];
            if (row.origin === 'BackOffice') {
                actions.push(
                    <GridActionsCellItem
                        key="enter"
                        icon={<ChevronRight />}
                        label="Enter"
                        className="textPrimary"
                        color="inherit"
                        onClick={onEnter(row)}
                    />
                );
            }
            return actions;
        }
    }
];

export const listValuesColumns = (
    onToggleStatus: (row: ListValue) => void,
    onLinkTenants: (row: ListValue) => void,
    objectProperties: CustomObjectProperty[] | undefined
): GridColDef<ListValue>[] => [
    {
        field: 'value',
        headerName: 'List Value',
        width: 300,
        editable: true,
        renderCell: ({ value, row }) => {
            let trueValue = value;
            if (row.dynamicObjectValue) {
                const propertiesValues = row.dynamicObjectValue.objectValues
                    .filter((item) => item.objectProperty.isDisplayable && item.objectProperty.enabled)
                    .sort((a, b) => +(a.objectProperty.order || 0) - +(b.objectProperty.order || 0));
                trueValue = propertiesValues
                    .map((item) => {
                        if (item.objectProperty.dataType === 'date') return dayjs(item.value).format('YYYY-MM-DD');
                        if (item.objectProperty.dataType === 'datetime') return dayjs(item.value).format('YYYY-MM-DD hh:mm a');
                        if (item.value === 'true') return 'Yes' as string;
                        if (item.value === 'false') return 'No' as string;
                        if (item.objectProperty.dataType === 'boolean') return item.value ? 'Yes' : 'No';

                        return item.value;
                    })
                    .join(', ');
                // No display value setted
                const objectDisplayValuesValidation = objectProperties?.filter((item) => item.isDisplayable && item.enabled) ?? [];
                if (objectDisplayValuesValidation.length === 0)
                    trueValue = 'No display value setted, please set display values on object configuration';
                // No display value filled
                if (trueValue === '') trueValue = 'No display value filled, please fill at least one display value property';
            }

            return <div>{trueValue}</div>;
        }
    },
    {
        field: 'enabled',
        headerName: 'Status',
        width: 100,
        renderCell: ({ value, row }) =>
            value ? (
                <Chip
                    onClick={() => onToggleStatus(row)}
                    label="Active"
                    size="small"
                    sx={(theme) => ({
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
                        color: theme.palette.success.dark
                    })}
                />
            ) : (
                <Chip
                    onClick={() => onToggleStatus(row)}
                    label="Inactive"
                    size="small"
                    sx={(theme) => ({
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light + 80,
                        color: theme.palette.orange.dark
                    })}
                />
            )
    },
    {
        field: 'isDefault',
        headerName: 'Default',
        width: 100,
        editable: true,
        renderCell: ({ value }) =>
            value ? (
                <Chip
                    label="Yes"
                    size="small"
                    sx={(theme) => ({
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
                        color: theme.palette.success.dark
                    })}
                />
            ) : (
                <Chip
                    label="No"
                    size="small"
                    sx={(theme) => ({
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light + 80,
                        color: theme.palette.orange.dark
                    })}
                />
            ),
        renderEditCell: (props) => <GridToggleChipAdapter {...props} trueLabel="Yes" falseLabel="No" />
    },
    {
        field: 'linkedTenant',
        headerName: 'Linked tenants',
        align: 'center',
        width: 170,
        valueFormatter: ({ value }) => value?.length || 0,
        renderCell({ value, row }) {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%', alignItems: 'center' }}>
                    <span>{value?.length || 0}</span>

                    <IconButton onClick={() => onLinkTenants(row)} disabled={row.isNew}>
                        <Link />
                    </IconButton>
                </Box>
            );
        }
    },
    {
        field: 'createdAt',
        type: 'date',
        headerName: 'Date Created',
        width: 200,
        valueFormatter: ({ value }) => (value ? format(new Date(value), 'yyyy/MM/dd p') : ''),
        flex: 0.5
    },
    {
        field: 'updatedAt',
        type: 'date',
        headerName: 'Date Updated',
        width: 200,
        valueFormatter: ({ value }) => (value ? format(new Date(value), 'yyyy/MM/dd p') : ''),
        flex: 0.5
    }
];

export const tenantGridColumns: GridColDef[] = [
    { field: 'id', headerClassName: 'bold-header', headerName: 'ID', width: 70 },
    { field: 'name', headerClassName: 'bold-header', headerName: 'Name', width: 180 },
    { field: 'description', headerClassName: 'bold-header', headerName: 'Description', width: 180 },
    {
        field: 'url',
        headerClassName: 'bold-header',
        headerName: 'URL',
        cellClassName: 'text-secondary',
        width: 180,
        valueFormatter: ({ value }) => (value ? `/${value}` : '')
    },
    {
        field: 'createdAt',
        headerClassName: 'bold-header',
        headerName: 'Date Created',
        width: 180,
        valueFormatter: ({ value }) => (value ? `${dayjs(value).format('DD/MM/YYYY')}` : '')
    },
    {
        field: 'users',
        headerClassName: 'bold-header',
        headerName: 'User',
        width: 80,
        valueFormatter: ({ value }) => (value ? `${value.length}` : '0')
    },
    {
        field: 'children',
        headerClassName: 'bold-header',
        headerName: 'Children',
        width: 100,
        valueFormatter: ({ value }) => (value ? `${value.length}` : '0')
    },
    {
        field: 'tenantType',
        headerClassName: 'bold-header',
        headerName: 'Type',
        width: 100,
        valueFormatter: ({ value }) => (value.length ? `${value.map((type: ITenantType) => type.name).join(', ')}` : '0')
    }
];
