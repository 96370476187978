/* eslint-disable no-nested-ternary */
import { useQuery } from '@apollo/client';
import { CancelOutlined, Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from '@mui/material';
import { QUERY_GET_TENANTS } from 'graphql/queries/tenants';
import React, { useEffect, useState } from 'react';
import { IGetTenants } from 'views/backoffice/tenants/types';
import { tenantGridColumns } from '../assets';
import { StripedDataGrid } from './List';
import { CustomTenantFilters } from 'views/backoffice/transferSettings/types';

export type TenantsDialogProps = {
    open: boolean;
    value: string[];
    onClose: () => void;
    onLinkTenants: (data: number[]) => Promise<void>;
    onUnlinkAllTenants: (data: number[]) => Promise<void>;
    customFiltersTenants?: CustomTenantFilters;
};

export const TenantsDialog = ({ open, value, onClose, onLinkTenants, onUnlinkAllTenants, customFiltersTenants }: TenantsDialogProps) => {
    const [isLoading, setisLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [ids, setIds] = useState<string[]>([]);
    const { loading, data } = useQuery<IGetTenants>(QUERY_GET_TENANTS);

    const tenantList = data?.getTenants || [];

    let filteredTenants = tenantList.filter(
        (tenant) => tenant.name.toLowerCase().includes(searchText.toLowerCase()) || ids.includes(tenant.id)
    );

    if (customFiltersTenants) {
        filteredTenants = filteredTenants.filter(
            (item) =>
                // (customFiltersTenants.types ? item.tenantType.some((el: any) => customFiltersTenants.types?.includes(+el.id)) : true) &&
                (customFiltersTenants.excludeByOthers ? !customFiltersTenants.excludeByOthers.includes(+item.id) : true) &&
                (customFiltersTenants.excludeBySelf ? !customFiltersTenants.excludeBySelf.includes(+item.id) : true)
        );
    }

    const handleClose = () => {
        setSearchText('');
        onClose();
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const handleSubmit = (submitFn: (data: number[]) => Promise<void>, link: boolean) => async () => {
        setisLoading(true);
        if (link) await submitFn(ids.map((el) => Number(el)));
        else await submitFn([]);
        setSearchText('');
        onClose();
        setisLoading(false);
    };

    useEffect(() => {
        if (value?.length) setIds([...value]);
        else setIds([]);
    }, [value]);

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg" aria-labelledby="form-dialog-title" fullWidth>
            <DialogTitle id="form-dialog-title" sx={{ px: 2, pt: 1 }}>
                <Grid container justifyContent="space-between" sx={{ paddingLeft: '1rem' }}>
                    <Grid container item xs={10}>
                        <Grid item xs={12}>
                            Linked Tenants
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="caption" color="text.primary">
                                Select the tenants you want to link
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs="auto">
                        <IconButton onClick={handleClose} color="secondary">
                            <CancelOutlined />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent sx={{ pt: '20px !important', pb: 0, height: 500, overflowY: 'hidden' }}>
                <TextField
                    label="Search tenants"
                    variant="filled"
                    size="small"
                    value={searchText}
                    onChange={handleSearchChange}
                    InputProps={{
                        disableUnderline: true,
                        sx: { borderRadius: '50px', paddingLeft: 2 },
                        endAdornment: searchText.length ? (
                            <IconButton onClick={() => setSearchText('')}>
                                <Close />
                            </IconButton>
                        ) : undefined
                    }}
                    InputLabelProps={{ sx: { paddingLeft: 2 } }}
                    fullWidth
                />
                <StripedDataGrid
                    loading={loading}
                    columns={tenantGridColumns}
                    rows={filteredTenants}
                    selectionModel={ids}
                    onSelectionModelChange={(newVal) => {
                        console.log('newVal', newVal);
                        setIds(newVal as string[]);
                    }}
                    getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
                    sx={{ height: '90%' }}
                    hideFooter
                    checkboxSelection
                    disableSelectionOnClick
                />
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'flex-end' }}>
                <Button variant="outlined" color="secondary" onClick={handleSubmit(onUnlinkAllTenants, false)}>
                    Unlink All
                </Button>
                {ids.length > 0 && (
                    <LoadingButton variant="contained" loading={isLoading} color="secondary" onClick={handleSubmit(onLinkTenants, true)}>
                        Link {ids.length ? (ids.length === tenantList.length ? 'All' : `(${ids.length})`) : ''} Tenants
                    </LoadingButton>
                )}
            </DialogActions>
        </Dialog>
    );
};

// const StyledSearchField = styled(TextField)(({ theme }) => ({

// })
